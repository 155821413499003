.notification-banner {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
    background-color: $porcelain;
    padding: rem(15) rem(18);

    &.alert-inline{
        left: 0;
        margin: 0;
        right: 0;
        max-width: 100%;
        background: transparent;
        .icon svg{
            fill:black;
        }
        .text{
            color:black;
        }
        .close-modal{
            display: none;
        }
    }
    .main__width-constraint {
        max-width: calc(100% - 10px);  
        position: relative;
    }
    &.hide {
        display: none;
    }
    &.alert {
        background-color: $warning;
        position: fixed;
        top: 0;
        z-index: 23;
    }
    &.warning {
        background-color: $warning;
        left: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100vw;
        position: relative;
        right: auto;
        width: 100%;
    }
    &.success {
        position: fixed;
        top: 0;
        background-color: $success;
        z-index: 23;
    }
    &.promoBanner {
        background-color: #D9F2EA;
        left: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100vw;
        position: relative;
        right: auto;
        width: 100%;
    }

    .icon {
        position: absolute;
        transform: translateY(-50%);
        display: inline-block;
        top: 50%;
        width: 25px;
        height: 24px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .text {
        display: inline-block;
        margin-left: rem(35);
        font-size: rem(12);
        font-weight: 500;
        color: $mine-shaft;
        max-width: 80%;
        
        &-body {
            display: block;
            margin-top: rem(15);
            font-size: rem(12);
            color: $mine-shaft;
            @include breakpoint($medium) {
                font-size: rem(16);
            }
        }
    }
    .close-modal {
        width: 16px;
        height: 16px;
        right: rem(10);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(10);
        @include breakpoint($large) {
            right: 0;
        }
        &__btn {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0;
            border: none;
            background: transparent;
            &::before,
            &::after {
                content:'';
                position: absolute;
                top: 0;
                width: 16px;
                height: 1px;
                background-color: $mine-shaft;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
    &.litepay {
        margin: rem(20) rem(-20);
        padding-bottom: 1.875rem;
        padding-top: 1.875rem;
        width: calc(100% + 2.5rem);
        &.refill{
            margin: 0rem;
            width:calc(100%);
        }
        .modal-item {
            display: inline-block;
            margin-top: rem(10);
        }
        .well {
            @include breakpoint($medium) {
                padding: rem(30) rem(60);
                &__close {
                right: rem(40);
                left: auto;
                }
            }
            .modal__close .icon {
                transform: none;
                left: auto;
            }
            .modal__content {
                p {
                background: none!important;
                }
            }
        }
        .well__text {
            .icon {
                left: rem(20);
            }
            display: inline-block;
            p {
                padding: 0;
            }
        }
        .icon {
          @include breakpoint($medium) {
            left: rem(10)
          }
        }
        .text {
          font-size: .875rem;
          font-weight: 200;

          @include breakpoint($medium) {
              font-size: 1.125rem;
              margin-left: rem(60)
          }

          a {
            font-size: rem(14);
            font-weight: 400;
            text-decoration: none;

            @include breakpoint($medium) {
              font-size: 1.125rem;
            }
          }
        }
      }
    &--contains-modal {
      .modal-item {
        display: block;
        margin-left: rem(35);

        @include breakpoint($medium) {
          margin-left: rem(60)
        }
      }
      
    }
}